@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700,900&display=swap');

.page {
  width: 100%;
  min-height: 100vh;
  display: block;
}

.page.with-bg {
  background-size: cover;
}

.page .bg-img {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
}

.page .bg-img img {
  width: 100%;
}

@font-face {
  font-family: 'Qualey';
  src: url(/fonts/Qualey.woff), url(/fonts/Qualey.ttf);
}